import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import APCategoryBtns from '../../components/preowned/APCategoryBtns'

// markup
const APMillenary = () => {
  const data = useStaticQuery(
    graphql`
      query queryAPMillenary {
        products: allStrapiProduct(
          filter: { brand: { eq: "Audemars Piguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Audemars Piguet Millenary Watches'}
      canonical={'/fine-watches/audemars-piguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Audemars Piguet Millenary watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/breguet/">
              <StaticImage
                src="../../images/preowned/used-audemars-piguet-millenary-banner.jpg"
                alt="Pre-Owned Certified Used Breguet Watches Header"
                aria-label="Used Breguet Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">About Pre-Owned Used Audemars Piguet Millenary</h1>
            <p>
              <a href="/fine-watches/audemars-piguet/">Audemars Piguet</a> launched the Millenary
              watch in 1995 to celebrate the upcoming new millennium. Pre-owned Millenary watches
              are some of the most creative Audemars Piguet timepieces in the secondary market
              thanks to their distinctive cases, imaginative dials, and innovative details. If
              you’re looking for a top-notch pre-owned luxury watch that few others own, a
              secondhand Audemars Piguet Millenary watch is a great place to start.
              <h2 className="pt-20">
                Buying and Selling Pre-Owned Used Audemars Piguet Millenary Watches
              </h2>
              <br />
              Pre-owned Audemars Piguet Millenary watches for men and women all feature oval-shaped
              cases with an east-west assignment. Dial variations are plentiful among secondhand
              Audemars Piguet Millenary watches, often unconventional in design. Furthermore, some
              used Millenary Audemars Piguet watches will have exhibition dials for a view of the
              movements inside the watch.
              <br />
              <br />
              An important milestone of the{' '}
              <a href="/fine-watches/audemars-piguet/">Audemars Piguet</a> Millenary collection was
              the introduction of the Caliber 4101 in 2011, which boasted an unusual reverse
              architecture. The unique movement structure, along with a sapphire exhibition dial,
              allows wearers to see the Millenary’s mechanism in action from the front of the watch
              rather than the back. Of course, in true{' '}
              <a href="/fine-watches/audemars-piguet/">Audemars Piguet</a> tradition, the Millenary
              is also available in various complications such as chronographs, calendars, and more.
              <br />
              <br />
              There is also a wide range of pre-owned ladies’ Audemars Piguet Millenary watches,
              often crafted from precious metals and embellished with dazzling diamonds.
              <br />
              <br />
              <i>Popular Pre-Owned Used Audemars Piguet Millenary References</i>
              <br />
              <br />
              <a href="/w521746-audemars-piguet-millenary-4101-47mm-4/">
                <li>Pre-owned Audemars Piguet Millenary ref. 15350</li>
              </a>
              <a href="/w521279-audemars-piguet-millenary-39mm-154049/">
                <li>Pre-owned Audemars Piguet Millenary ref. 154049</li>
              </a>
              <a href="/w522640-audemars-piguet-millenary-41mm-25822s/">
                <li>Pre-owned Audemars Piguet Millenary ref. 25822</li>
              </a>
              <a href="/">
                <li>Pre-owned Audemars Piguet Millenary ref. 26150</li>
              </a>
              <a href="/">
                <li>Pre-owned Audemars Piguet Millenary ref. 77247</li>
              </a>
              <a href="/">
                <li>Pre-owned Audemars Piguet Millenary ref. 77244</li>
              </a>
              <br /> {/* Notes Hello World */}
              Grays & Sons are experts in buying and selling secondhand luxury watches with over 40
              years of experience in the business. We have a team of watch specialists that can help
              you buy the pre-owned Audemars Piguet Millenary watch that’s right for you.
              <br />
              <br />
              Every secondhand Millenary luxury watch for sale on <a href="/">Gray & Sons’</a>{' '}
              website is in stock and ready to ship immediately. Furthermore, as an independent{' '}
              <a href="/fine-watches/audemars-piguet/">Audemars Piguet</a> watch dealer,{' '}
              <a href="/">Gray & Sons’</a> have both price flexibility and inventory control. Make a
              deal with one of our decision-makers today to purchase the best secondhand Audemars
              Piguet Millenary watch for your taste and budget.
              <br />
              <br />
              If, on the other hand, you want to know, “Where can I sell my used Audemars Piguet
              Millenary watch near me?” Gray & Sons buys luxury watches too. Visit{' '}
              <a href="https://sellusyourjewelry.com/">sell us your jewelry</a> to start the process
              of selling your secondhand Audemars Piguet Millenary watch.
              <br />
              <h2 className="pt-20">
                Gray & Sons is the Best Source for Buying and Selling Pre-Owned Millenary Watches
              </h2>
              <br />
              We’re proud that over the last four decades, our clients have selected Gray & Sons as
              the number one seller and buyer of used Audemars Piguet Millenary watches.
              <br />
              If you’re in the Miami area, visit the <a href="/"> Gray & Sons boutique, </a>
              located near the prestigious Bal Harbour Shops for the best selection of pre-owned
              fine timepieces. We are open six days a week and our watch experts can assist while
              your browse our collection of secondhand Audemars Piguet Millenary watches for sale.
              Gray & Sons also has an independent Audemars Piguet service facility on site for any
              repair needs.
              <br />
            </p>
            <div className="w-full flex justify-start uppercase">
              <a href="/fine-watches/audemars-piguet/">
                <button className="uppercase my-4">SHOP Audemars Piguet WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK BREGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <APCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default APMillenary
